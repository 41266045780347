@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Ocultar scrollbars */
.hide-scrollbar {
    scrollbar-width: none;
    /* Para Firefox */
    -ms-overflow-style: none;
    /* Para Internet Explorer y Edge */

    /* Para Chrome, Safari y Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

/* RESET */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    scroll-padding-top: 2rem;
    transition: all 200ms ease;
    font-weight: 400;
}


img {
    max-width: 100%;
}

html {
    font-size: 10px;
}
